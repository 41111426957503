<template>
  <div class="d-flex align-items-center">
    <div class="mr-2 filter-select">
      <label>{{ $t('Region Grup') }}</label>
      <v-select
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :value="grupFilter"
        :options="regionGrupList"
        class="w-100"
        :reduce="(val) => val.id"
        label="grupName"
        @input="(val) => $emit('update:grupFilter', val)"
      />
    </div>
    <div class="d-flex align-items-center justify-content-end mt-2">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          :value="searchQuery"
          @input="(val) => $emit('update:searchQuery', val)"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
      </b-input-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: null,
    },
    grupFilter: {
      type: Number,
      default: null,
    },
  },
  methods: {
    loadRegionGrups() { 
      this.$store.dispatch('presetRegionGrupManagement/fetchPresetRegionPcaGrupList', {});
    },
  },
  computed: {
    regionGrupList() {
      return this.$store.getters['presetRegionGrupManagement/getPcaRegionGrupList'];
    },
  },
  mounted() {
    this.loadRegionGrups();
  },
}
</script>

<style lang="scss" scoped>
.filter-select {
  min-width: 200px;
}
</style>
